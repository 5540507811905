<template>
  <div>
    <v-form v-model="isformValid" ref="form" lazy-validation>
      <v-row style="padding: 20px">
        <v-col cols="4">
          <v-text-field
            label="Nombre(s)"
            v-model="formData.nombre"
            :rules="[$rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Apellido Paterno"
            v-model="formData.apellidoPaterno"
            :rules="[$rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Apellido Materno"
            v-model="formData.apellidoMaterno"
            :rules="[$rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-select
            label="Genero"
            v-model="formData.genero"
            :items="generoItems"
            item-value="value"
            item-text="text"
            :rules="[$rules.required]"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Fecha de Nacimiento"
            type="date"
            v-model="formData.fechaNacimiento"
            prepend-icon="mdi-calendar"
            :rules="[$rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            label="Estado Civil"
            v-model="formData.estadoCivil"
            :items="estadoCivilItems"
            :rules="[$rules.required]"
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-text-field label="RFC" v-model="formData.rfc"></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            label="Correo Electrónico"
            v-model="formData.correo"
            :rules="[$rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Teléfono"
            v-model="formData.telefonoMovil"
            :rules="[$rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" outlined class="mr-2" @click="$emit('cancel')">
            <v-icon small class="mr-2">mdi-close</v-icon>
            <span>Cancelar</span>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!isformValid"
            :loading="loading"
            @click="saveCliente"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            <span>Guardar cambios</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { editarCliente } from "../../services/clienteService";

export default {
  props: {
    cliente: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isformValid: false,
      loading: false,

      generoItems: [
        {
          value: "M",
          text: "Masculino",
        },
        {
          value: "F",
          text: "Femenino",
        },
      ],
      estadoCivilItems: ["Soltero(a)", "Casado(a)"],

      formData: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        genero: "",
        fechaNacimiento: "",
        estadoCivil: "",
        rfc: "",
        correo: "",
        telefonoMovil: "",
      },
    };
  },

  mounted() {
    this.loadCurrentData();
  },

  methods: {
    loadCurrentData() {
      Object.keys(this.formData).forEach((clienteKey) => {
        this.formData[clienteKey] = this.cliente[clienteKey];
      });
    },

    async saveCliente() {
      await this.$refs.form.validate();
      if (!this.isformValid) return;

      this.loading = true;

      try {
        await editarCliente(this.cliente.id, this.formData);
        this.$emit("clienteSaved");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
