import { mainAxios } from "../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "./mainService";

export const editarCliente = async (clienteId, bodyData) => {
  try {
    const response = await mainAxios.put(
      `/v1/modificacionCliente/update/${clienteId}`,
      bodyData,
      getDefaultHeaders()
    );

    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
